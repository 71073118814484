import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import * as $ from 'jquery';

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit {

  notificationSize:number = 0;
  errors:boolean = false;
  showNotifications:boolean = false;
  menuLinks = [
    {
      label: "Dashboard",
      routerLink: "/dashboard",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "fa-tachometer-alt-fast",
    },
    {
      label: "Stock",
      routerLink: "/stock",
      roles:[1,2],
      iconType: "fal",
      iconName: "far fa-box-open",
    },
    {
      label: "Orders",
      routerLink: "/order",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "far fa-copy",
    },
    {
      label: "Doors",
      routerLink: "/door",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "far fa-door-open",
    },
    {
      label: "Extras",
      routerLink: "/extra",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "far fa-key",
    },
    {
      label: "Suppliers",
      routerLink: "/supplier",
      roles:[1,2],
      iconType: "fal",
      iconName: "far fa-truck-loading",
  },
  {
    label: "Clients",
    routerLink: "/client",
    roles:[1,2],
    iconType: "fal",
    iconName: "far fa-user",
},
	{
		label: "Stock Type",
		routerLink: "/stocktype",
		roles:[1,2,3],
		iconType: "fal",
		iconName: "far fa-box-open",
	},
    {
      label: "Users",
      routerLink: "/user",
      roles:[1,2],
      iconType: "fal",
      iconName: "far fa-users",
    },
    // {
    //   label: "Support",
    //   routerLink: "/support",
    //   roles:[1,2,3],
    //   iconType: "fal",
    //   iconName: "fas fa-phone",
    // }
  ];
  ngOnInit() {
    this.changeLayout("menu-pin");
    //Will sidebar close on screens below 1024
    this.autoHideMenuPin();
    // document.documentElement.style.setProperty('--primary-color', '#546E87');
    // document.documentElement.style.setProperty('--secondary-color', '#7DA1B5');
    this.filterMenu(this.authService);
    this.getNotificationSize();
  }

  getLogo(){
    return this.authService.getCompanyLogo();
  }

  signout(){
    this.authService.signout();
  }

  isSystemAdmin() : boolean{
    if(this.authService.getUserRole() == 1){
      return true;
    }
    return false;
  }

  getNotificationSize(){
    let _this = this;
    this.notificationService.getCount().subscribe(res => {
      _this.notificationSize = res['data']['count'];
    },
    err => {
      console.log(err);
    })
  }

  getFullName(){
    if(!this.authService.user){
      return "";
    }
    return this.authService.user.first_name+' '+this.authService.user.last_name;
  }

  filterMenu(authService){
    let role = authService.getUserRole();
    for(let parent = 0; parent < this.menuLinks.length;parent++){
        if(!this.menuLinks[parent].roles.includes(role)){
            this.menuLinks[parent] = null;
        }
    }
    this.menuLinks = this.menuLinks.filter(Boolean);
}

}
